<template>
    <v-card class="d-flex flex-column" width="300">
        <v-card-text>
            <v-row>
                <v-col cols="2">
                    <v-switch
                        dense
                        v-model="fromEnabled"
                    ></v-switch>
                </v-col>
                <v-col>
                    <DateSelector
                        :formDates.sync="from"
                        :label="'Inizio Validità'"
                        :disabled="!fromEnabled"
                    ></DateSelector>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <v-switch
                        dense
                        v-model="toEnabled"
                    ></v-switch>
                </v-col>
                <v-col>
                    <DateSelector
                        :formDates.sync="to"
                        :label="'Fine Validità'"
                        :disabled="!toEnabled"
                    ></DateSelector>
                </v-col>
            </v-row>
            <v-switch
                dense
                v-model="active"
                :label="active ? 'Attive' : 'Attive'"
            ></v-switch>
            <v-switch
                dense
                v-model="expired"
                :label="expired ? 'Scadute' : 'Scadute'"
            ></v-switch>
            <v-switch
                dense
                v-model="deleted"
                :label="deleted ? 'Eliminate' : 'Eliminate'"
            ></v-switch>
            <v-switch
                dense
                v-model="suspended"
                :label="suspended ? 'Sospese' : 'Sospese'"
            ></v-switch>
            <v-switch
                dense
                v-model="toPublish"
                :label="
                    toPublish
                        ? 'Non pubblicate'
                        : 'Non pubblicate'
                "
            ></v-switch>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export const DateSelector = () => import("../core/DateSelector");


import * as filterActions from "../../store/actions/filter";
export default {

    components: {
        DateSelector
    },

    computed: {
        ...mapState({
            promoFilter: state => state.filterModule.promoFilter,
        }),

        fromEnabled: {
            get() {
                return this.promoFilter.fromEnabled;
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'fromEnabled',value: newValue});
            }
        },

        toEnabled: {
            get() {
                return this.promoFilter.toEnabled;
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'toEnabled',value: newValue});
            }
        },

        from: {
            get() {
                return this.promoFilter.from.substring(0, 10);
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'from',value: `${newValue}T00:00:00Z`});
            }
        },

        to: {
            get() {
                return this.promoFilter.to.substring(0, 10);
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'to',value: `${newValue}T00:00:00Z`});
            }
        },

        showall: {
            get() {
                return this.promoFilter.all;
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'all',value: newValue});
            }
        },

        active: {
            get() {
                return this.promoFilter.active;
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'active',value: newValue});
            }
        },

        deleted: {
            get() {
                return this.promoFilter.deleted;
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'deleted',value: newValue});
            }
        },

        expired: {
            get() {
                return this.promoFilter.expired;
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'expired',value: newValue});
            }
        },

        suspended: {
            get() {
                return this.promoFilter.suspended;
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'suspended',value: newValue});
            }
        },

        toPublish: {
            get() {
                return this.promoFilter.toPublish;
            },
            set(newValue) {
                this.updatePromoFilterSwitch({key: 'toPublish',value: newValue});
            }
        },
    },

    methods: {
        ...mapMutations({
            updatePromoFilterSwitch:filterActions.FILTER_PROMOS_SET_SWITCH
        })
    }
};
</script>

<style scoped>

.option-menu.v-btn::before {
  opacity: 0;
}
</style>
